#pane-1-inner {
  padding-top: 3em;
}

#pane-3-inner {
  padding-top: 3em;
}

#details-brand {
  padding-bottom: 3em;
}

#sig-box {
  border: 1px solid lightgrey;
}
#sig-arrow {
  position: absolute;
  bottom: -2px;
  right: 1em;
  width: 10em;
  height: auto;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#marketing-list > li {
  margin-left: -1.5em;
}

.nav-text {
  color: lightgrey;
  font-size: 1.25em;
  padding-bottom: 0.25em;
  padding-left: 1em;
  padding-right: 1em;
}



.nav-container {
  margin-bottom: 1em;
}
.savings-text-detail {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1.75em;
  text-align: left;
}

.savings-bar-container-height-override {
  height: 3em;
}

#terms {
  position: absolute;
  left: 20px;
  bottom: -20px;
  color: lightgrey;
}

#details-bars-container {
  padding-top: 3em;
  padding-bottom: 3em;
}

#details-button-container {
  //margin-top: 2.5em;
}


.details-button-text {
  font-size: 1.5em;
}

.button-chevron {
  padding-top: 4px;
  padding-bottom: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 2em;
}

.button-chevron-left {
  padding-top: 4px;
  padding-bottom: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 1.5em;
}
.button-row {
  padding-top: 2em;
  padding-bottom: 2em;
}

.button-col {
  padding-left: 0;
  padding-right: 0;
}

.agreementText {
  color: lightgrey;
  font-size: 0.90em;
}

#sig-error {
  position: absolute;
  top: -1.5em;
  right: 5em;
  color: red;
}

.price-text-big {
  font-size: 3em;
}
.price-text {

}

.marketing-text {
  font-color: grey;
}
.savings-bar-container-height-override {
  height: 3em !important;
}
