@import 'colors';

.navbar {
  background: $stryde-orange;
  color: $white;
}

.navbar-brand > img {
  height: 50px;
}

#cl-header > h1 {
  text-align: center;
}

#cl-contact > span {
  text-align: center;
  display: block;
}
#cl-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
}
#header-row {
  border-bottom: 1px solid black;
}

#borrower-add-button {
  color: green;
  margin-right: 5px;
}
#borrower-remove-button {
  color: red;
}

span.italic {
  font-style: italic;
}

span.bold {
  font-style: bold;
}

p.stryde-bold {
  color: $stryde-orange;
  font-weight: 600;
  font-size: 1.25em;
  text-align: center;
}

.liquidity-checkbox > label {
  margin-bottom: 1em;
}

.pl-0 {
  padding-left: 0;
  padding-right: 0;
}

.row > h4 {
  padding-left: 15px;
  padding-right: 15px;
}

.form-section {
  display: none;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 2em;
  padding-bottom: 1em;
  margin-left: -15px;
  margin-right: -15px;
}

.form-section.current {
  display: inherit;
}

.ajax-file-upload-preview{
  display: block !important;
}

#product-select-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 105vw;
  height: 100vh;
  background: #941616;
  color: white;
  z-index: 9990;
}

#product-select-screen select {
  color: white;
  background-color:$stryde-blue;
  position:relative;
  top:5px;
  font-size:1.25em;
  border: none;
}
