@import 'colors';

$brand: 'stryde';
$brandcolor: $stryde_orange;
$brandsecondarycolor: $stryde_blue;

.ui-datepicker-header {
    color: white;
}

.ui-datepicker-title > span {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
}

div.ui-datepicker{
    font-size:2em;
}

a.ui-state-default {
    color: red;
}

.ui-datepicker-prev, .ui-datepicker-next {
    margin-top: 0.18em;
}

#datepicker > div > table > tbody > tr > td > a {
    color: $brandsecondarycolor;
}

#datepicker > div > table > thead > tr > th > span {
    color: $brandsecondarycolor;
}

.ui-state-active {
    background: $brandcolor !important;
    color: white !important;
    border: none !important;
}

.time-container{
    margin-left: 5px;
    margin-right: 5px;
}

.time {
    font-size: 20px;
    text-align: center;
    height:2em;
    padding:0px;
}

.time > span {
    vertical-align: -5px;
}

.time:hover {
    background: $stryde-orange;
    color: white;
    cursor: pointer;
}

.confirm {
    margin-top:10px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: $stryde-orange;
    color: white;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
}

.ddlTimezone {
    font-size: large;
    margin-bottom: 5px;
}

.transparent {
    opacity: 0.5;
}

#time-select-box{
    padding-left: 0px;
    padding-right: 0px;
    border: 1px solid #CCC;
    border-radius: 3px;
}

#time-select-header{
    margin-top:5px;
    margin-right: 5px;
    margin-left: 5px;

    background: $stryde-orange;
    color: white;
}