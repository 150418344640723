// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
html, body {
  height: 100%;
  width: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}


#pane-1 {
  background-image: url('/images/headerimage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  //padding-top: 12em;
  display: flex;
  align-items:center;
}

#pane-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  background: white;
  padding-top: 4em;
  padding-bottom: 4em;
}

#pane-2-row-1 {
  margin-bottom: 2em;
}
.savings-bar-pane {
  margin-top: 1.5em;
}


#pane-3-text {
  font-size: 2vw;
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 600;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 2em;

}

#pane-3 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#pane-4 {
  height: 65%;
  padding-bottom: 3em;
}
.item {
  padding-bottom: 4em;
}


#form-box {
  padding: 3em;
}

#footer {
  z-index: 100;
}

#iphone-survey {
  padding-left: 5em;
}

.person-circle {
  width: 200px;
  height: 200px;
}

.carousel-image {
  height: 400px;
  width: auto;
}
#candidate-form {
  height: 300px;
  width: auto;
}

.document-image {
  width: 80%;
  height: auto;
}


.carousel-text {
  font-size: 2.25em;
}

.carousel-text-small {
  font-size: 1.25em;
}

.carousel-text-padded {
  padding-top:2em;
}
.carousel-left-half {
  padding-left: 15em;
}

.carousel-right-half {
  padding-right: 15em;
}

.item {
  height: 100%;
}

.item-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10em;
  padding-right: 10em;
}


.animation-fadeIn {
  animation: fadeIn 0.25s forwards;
}

.animation-fadeOut {
  animation: fadeOut 0.25s forwards;
}

@keyframes fadeIn {
  from { background-color: rgba(255,255,255,0)}
  to { background-color: rgba(255,255,255,1)}
}

@keyframes fadeOut {
  from { background-color: rgba(255,255,255,1)}
  to { background-color: rgba(255,255,255,0)}
}

.header-text {
  font-size: 2.5em;
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 600;
  text-align: left;
}
.savings-text {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 2.5em;
  text-align: left;
}

.bar-text {
  color: #575757;
  font-size: 0.90em;
  display: block;
  text-align: center;
}




.slide-in {
  animation: slideIn 1s forwards;
}

.chevron {
  margin-right: -2.13em;
  width: auto;
  height: 4em
}
.savings {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1.5em;
  margin-right: 3em;
}

.compute-text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  color: white;
  z-index: 3;
  cursor: pointer;
}

.large-activate-button {
  border-radius: 10px;
  font-size: 2em;
  padding: 0.5em;
}

@keyframes slideIn {
  from { margin-left: -120%}
  to { margin-left: -2em }
}

@media (max-width: 1250px) {

}



/* Large devices (large desktops, 1200px and up) */
@media (min-width: 993px) {
  #brand {
    height: 4em;
  }

}

@media (min-width: 993px) and (orientation : portrait){
  #brand {
    height: 3em;
  }
  #pane-1 {
    background-position: -30em 0em;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width : 769px) and (max-width: 992px) {
  #brand {
    display: inline-block;
    height: 3em;
  }
 }

/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
  #brand {
    height: 2.5em;
    margin-left: 0.5em;
    margin-top: 0.5em;
  }
  #pane-1 {
    align-items: flex-end;
  }
  #pane-1-row-1 {
    padding-bottom: 3em;
  }

  #pane-2-row-1 {
    margin-bottom: 0em;
  }

  #pane-3 {
    background-position: -6em 0em;
  }
  #pane-3-text {
    font-size: 4vw;
  }


  #pane-4 {
    height: 100%;
  }

  .header-text {
    color: white;
  }

  .savings-text {
    font-size: 1.5em;
    color: white;
    margin-bottom: 0;
  }

  .savings-bar-pane {
    padding-top: 0em;
  }
  .savings {
    margin-right: 0;
  }
  .large-activate-button {
    font-size: 1.5em;
    border-radius: 5px;
  }

 }


@media (max-width: 768px) and (orientation : portrait) {
 #pane-1 {
   background-position: -25em;
   //padding-top: 15em;
 }
 #pane-3 {
   background-position: -25em 0em;
 }

 .large-activate-button {
   font-size: 1.25em;
   padding: 0.5em;
 }

 .item-content {
   padding-left: 0em;
   padding-right: 0em;
 }


 .carousel-image {
   height: auto;
   width: 300px;
   margin-left: 2em;
 }

}

@media (max-width: 768px) and (orientation : landscape) {
  .col-xs-12 {
    width: 80%;
  }
  .container {
    margin-left: 0;
  }

}


 @media(max-width: 375px) {

   .carousel-left-half {
     padding: 0em;
   }

   .carousel-right-half {
     padding: 0em;
   }



   .carousel-text {
     font-size: 2em;
   }

   .carousel-text-small {
     font-size: 1.25em;
   }

   .carousel-text-padded {
     padding-top:0.25em;
     padding-bottom: 0.5em;
   }

   #pane-4 {
   }

   .person-circle {
     width: 200px;
     height: 200px;
   }



   #pane-3 {
     background-position: -20em 0em;
   }


   #pane-3-text-container {
     padding: 0 !important;
   }

   .large-activate-button {
     padding: 0.5em;
     font-size: 1.15em;
   }

   .header-text{
     font-size: 2em;
   }
 }

 @media (max-width: 330px) {
   #brand{
      height: 2.5em;

    }
 }

 @media (max-width: 320px) {
   #brand{
      height: 2.5em;
    }
    #pane-3 {
      background-position: -18em 0em;
    }

 }
#pane-1-marketing-text, #marketing-list, .marketing-text{
  font-size: large;
}
#hire-pane{
  margin: 15px 0;
}
