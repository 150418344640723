#cs-brand {
  width: 100px;
  height: 100px;
  background: url('/images/gmglogo.png');
  background-size: cover;
  background-position: left;
}

#report-col {
  text-align: center;
}

#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 105vw;
  height: 100vh;
  background: #941616;
  color: white;
  z-index: 9999;
}

#transparent-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 105vw;
  height: 100vh;
  background: rgba(0,0,0, 0.5);
  z-index: 9999;
}

.full {
  width: 100%;
}

.half-height {
  height: 50%;
}

.full-height {
  height: 100px;
}

.flex-bottom {
  display: flex;
  align-items: flex-end;
}

.thick-bottom-border {
  border-bottom: 2px solid black;
}

.thick-gmg-bottom-border {
  border-bottom: 2px solid #941616;
}

.thin-bottom-border {
  border-bottom: 1px solid black;
}
.white-right-border {
  border-right: 1px solid white;
}

.dashed-border {
  border: 1px dashed black;
}
.dashed-border-lbr {
  border-left: 1px dashed black;
  border-right: 1px dashed black;
  border-bottom: 1px dashed black;
}

.gmg-red-header {
  font-size: 1.25em;
  color: #941616;
  font-weight: 600;
}

.gmg-bold {
  color: #941616;
  font-weight: 600;
}

.span-block {
  display: block;
}

.address-text {
  font-size: 0.90em;
}

.white-on-red {
  color: white;
  background: #941616;
}

.white-on-red > div {
  height: 4em;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lp-none {
  padding-left: 0px;
}
.rp-none {
  padding-right: 0px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.icon-gmg-red {
  color: #941616;
  padding-right: 5px;
}

.pt-pb-half-em {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.grey-panel {
  background: lightgrey;
}

.parsley-custom-error-message {
  list-style-type: none;
}

.parsley-errors-list {
  padding-left: 0;
  color: #941616;
}

.parsley-errors-list > li {
  list-style-type: none;
}

.input-error {
  background: #941616;
}

.gmg-button {
  margin-top: 1em;
  background: #941616;
  color: white;
}
.loader-container{
  background: white;
  border-radius: 20px;
}
.loader,
.loader:before,
.loader:after {
  background: white;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color:white;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

 }
