.collapsed {
  border: none;
  background: rgba(0,0,0,0);

}
 .nav {
   background: rgba(0,0,0,0);
 }
 .navbar-brand {
   padding: 0;
 }

 .modal-footer {
   border: none;
 }

 .carousel-control.left, .carousel-control.right {
    background-image: none;
}

 @media (max-width: 768px) {
   /*.form-group {
     margin-bottom: 0;

   }*/
 }
